import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Falcon\'s Landing — Sterling, Virginia' },
    { img: data.i2, caption: 'Falcon\'s Landing — Sterling, Virginia' },
    { img: data.i3, caption: 'Laterra at The King & The Bear — St. Augustine, Florida' },
    { img: data.i4, caption: 'Laterra at The King & The Bear — St. Augustine, Florida' },
    { img: data.i5, caption: 'Laterra at The King & The Bear — St. Augustine, Florida' },
    { img: data.i6, caption: 'St. George CCRC — Roswell, Georgia' },
    { img: data.i7, caption: 'St. George CCRC — Roswell, Georgia' },
    { img: data.i8, caption: 'St. George CCRC — Roswell, Georgia' },
    { img: data.i9, caption: 'The Terraces — New Orleans, Louisiana' },
    { img: data.i10, caption: 'The Terraces — New Orleans, Louisiana' },
    { img: data.i11, caption: 'Brooks ALF — Jacksonville, Florida' },
    { img: data.i12, caption: 'Brooks ALF — Jacksonville, Florida' },
    { img: data.i13, caption: 'Vinson Hall Senior Living — McLean, Virginia' },
    { img: data.i14, caption: 'The Residences at Christopher Heights — Northampton, Massachusetts' },
  ];

  return (
    <Layout>
      <SEO title="Gallery: Senior Housing" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>Gallery</h2>
        <h1 className='title title--xxxl color-primary'>Senior Housing</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>The Infinity Structural System has been utilized on dozens of senior
          housing and assisted living facilities ranging from 2-stories to 9-stories in
          height.  The Infinity System provides a non-combustible, high-quality, noise
          resistant building that helps ensure the safety of its elderly residents and
          peace-of-mind for their loved ones. Designers and Developers of senior housing
          projects select the Infinity System for speed of construction, economical/lower
          construction costs, lower long-term maintenance costs, high quality, rigid
          vibration-free concrete floor, and a longer building life expectancy.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
         query {
           i1: file(relativePath: { eq: "gallery/senior-housing/gallery/01-Falco040_(33943).png" }) {
             ...GalleryCarouselImageFragment
           }
           i2: file(relativePath: { eq: "gallery/senior-housing/gallery/02-Falco010_(33476).png" }) {
             ...GalleryCarouselImageFragment
           }
           i3: file(relativePath: { eq: "gallery/senior-housing/gallery/03-Later080_(40291).png" }) {
             ...GalleryCarouselImageFragment
           }
           i4: file(relativePath: { eq: "gallery/senior-housing/gallery/04-Later120_(40295).png" }) {
             ...GalleryCarouselImageFragment
           }
           i5: file(relativePath: { eq: "gallery/senior-housing/gallery/05-Later140_(40297).png" }) {
             ...GalleryCarouselImageFragment
           }
           i6: file(relativePath: { eq: "gallery/senior-housing/gallery/06-GCCRC040_(40313).png" }) {
             ...GalleryCarouselImageFragment
           }
           i7: file(relativePath: { eq: "gallery/senior-housing/gallery/07-GCCRC050_(41236).png" }) {
             ...GalleryCarouselImageFragment
           }
           i8: file(relativePath: { eq: "gallery/senior-housing/gallery/08-St._George-_Finished2_(NEW).JPG" }) {
             ...GalleryCarouselImageFragment
           }
           i9: file(relativePath: { eq: "gallery/senior-housing/gallery/09-Terra010_(42002).png" }) {
             ...GalleryCarouselImageFragment
           }
           i10: file(relativePath: { eq: "gallery/senior-housing/gallery/10-Terra020_(42003).png" }) {
             ...GalleryCarouselImageFragment
           }
           i11: file(relativePath: { eq: "gallery/senior-housing/gallery/11-BroFL010_(42859).png" }) {
             ...GalleryCarouselImageFragment
           }
           i12: file(relativePath: { eq: "gallery/senior-housing/gallery/12-BroFL020_(42860).png" }) {
             ...GalleryCarouselImageFragment
           }
           i13: file(relativePath: { eq: "gallery/senior-housing/gallery/13-VinHS010_(43035).png" }) {
             ...GalleryCarouselImageFragment
           }
           i14: file(relativePath: { eq: "gallery/senior-housing/gallery/14-ResCH050_(42961).png" }) {
             ...GalleryCarouselImageFragment
           }
         }
       `
